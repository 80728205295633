import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    position: "relative",
    width: "339px",
    height: "759px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    overflow: "hidden",
  },
  frame: {
    opacity: 0.644,
    fill: "rgba(151, 187, 215, 1)",
    position: "absolute",
    overflow: "visible",
    width: "339px",
    height: "698px",
    left: "", //65
    top: "0", //31
  },
  bezel: {
    fill: "rgba(0, 0, 0, 1)",
    position: "absolute",
    overflow: "visible",
    width: "329px",
    height: "688px",
    left: "5px",
    top: "5px",
  },
  screen: {
    fill: "#f1f1f1",
    position: "absolute",
    overflow: "visible",
    width: "306px",
    height: "664px",
    left: "16px",
    top: "18px",
  },
  backgroundImg: {
    position: "absolute",
    width: "307px",
    height: "664px",
    left: "16px",
    
    top: "18px",
    overflow: "visible",
  },
  speaker: {
    fill: "rgba(49, 49, 49, 1)",
    position: "absolute",
    overflow: "visible",
    width: "58px",
    height: "7px",
    left: "140px",
    top: "6px",
  },
  volumeControls: {
    position: "absolute",
    width: "3px",
    height: "173px",
    top: "170px",
    overflow: "visible",
  },
  volumeDown: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "55px",
    top: "118px",
  },
  volumeUp: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "55px",
    top: "52px",
  },
  silentToggle: {
    fill: "rgba(149, 175, 201, 1)",
    position: "absolute",
    overflow: "visible",
    width: "3px",
    height: "29px",
  },
  children: {
    borderRadius: "30px",
    position: "absolute",
    overflow: "hidden",
    width: "306px",
    height: "664px",
    left: "16px",
    top: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    zIndex: 1,
  },
});

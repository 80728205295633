import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { App } from "./App";
import "./global.css";
import "@mantine/core/styles.layer.css";

const main = async () => {
  const rootElement = document.getElementById("app");

  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  }
};

main();

globalThis.__ZOZIA__ = {
  meta: {
    META_PROJECT_VERSION: process.env.META_PROJECT_VERSION,
    META_PROJECT_NAME: process.env.META_PROJECT_NAME,
    META_IMAGE_VERSION: process.env.META_IMAGE_VERSION,
  },
};

import * as React from "react";

import { useStyles } from "./styles";

export function DeviceFrame(props) {
  const { backgroundImg, className, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <svg className={classes.frame}>
        <rect rx="53" ry="53" x="0" y="0" width="339" height="698"></rect>
      </svg>
      <svg className={classes.bezel}>
        <rect rx="47" ry="47" x="0" y="0" width="329" height="688"></rect>
      </svg>
      <svg className={classes.screen}>
        <rect rx="35" ry="35" x="0" y="0" width="306" height="664"></rect>
      </svg>
      <div className={className ? className : classes.children}>{children}</div>
      {backgroundImg && (
        <img className={classes.backgroundImg} src={backgroundImg} />
      )}
      <svg className={classes.speaker}>
        <rect rx="3.5" ry="3.5" x="0" y="0" width="58" height="7"></rect>
      </svg>
      <div className={classes.volumeControls}>
        <svg className={classes.volumeDown}>
          <rect rx="1" ry="1" x="0" y="0" width="3" height="55"></rect>
        </svg>
        <svg className={classes.volumeUp}>
          <rect rx="1" ry="1" x="0" y="0" width="3" height="55"></rect>
        </svg>
        <svg className={classes.silentToggle}>
          <rect rx="1" ry="1" x="0" y="0" width="3" height="29"></rect>
        </svg>
      </div>
    </div>
  );
}

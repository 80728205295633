import { Loader, Title } from "@mantine/core";
import * as React from "react";

import { DeviceFrame } from "./DeviceFrame";

export const Zozia = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    // <div className="mx-auto flex justify-center flex-col items-center">
    // </div>

    <div className="mx-4 grid place-items-center grid-cols-1 gap-8 sm:grid-cols-2">
      <p>
        Zozia jest platformą dla osób które chcą kupować i sprzedawać produkty
        lokalnie. Mobilna aplikacja pomaga użytkownikom przeglądać oferty w
        lokalizacjach z okolicy.
      </p>
      <DeviceFrame>
        {isLoading ? (
          <div className="absolute">
            <Loader />
          </div>
        ) : null}
        <iframe
          loading="lazy"
          onLoad={() => {
            setIsLoading(false);
          }}
          allow="geolocation 'self' https://app.zozia.app"
          src="https://app.zozia.app"
          title="Zozia"
          className="w-[550px] h-full border-none rounded-b-[2.5rem] user-select-none"
          style={{
            visibility: isLoading ? "hidden" : "visible",
          }}
        />
      </DeviceFrame>
    </div>
  );
};
